<template>
  <div class="get-employee">
    <div class="container">
      <div class="row">
        <transition
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
          mode="out-in"
        >
          <div class="col-12" v-if="!formSended" key="form">
            <div class="team-item team-item--simple">
              <div class="team-item__inner p-5">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <input-row
                        :label="ClinicName"
                        v-model="form.clinicName"
                        @input="$v.form.clinicName.$touch()"
                        @blur="$v.form.clinicName.$touch()"
                        :class="{
                          invalid:
                            $v.form.clinicName.$invalid &&
                            $v.form.clinicName.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        :label="Email"
                        v-model="form.email"
                        @input="$v.form.email.$touch()"
                        @blur="$v.form.email.$touch()"
                        :class="{
                          invalid:
                            $v.form.email.$invalid && $v.form.email.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        :label="Phone"
                        v-model="form.contactNumber"
                        @input="$v.form.contactNumber.$touch()"
                        @blur="$v.form.contactNumber.$touch()"
                        :class="{
                          invalid:
                            $v.form.contactNumber.$invalid &&
                            $v.form.contactNumber.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        :label="Address"
                        v-model="form.address"
                        @input="$v.form.address.$touch()"
                        @blur="$v.form.address.$touch()"
                        :class="{
                          invalid:
                            $v.form.address.$invalid && $v.form.address.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        :label="StaffAmount"
                        v-model="form.requiredEmployeeCount"
                        type="number"
                        @input="$v.form.requiredEmployeeCount.$touch()"
                        @blur="$v.form.requiredEmployeeCount.$touch()"
                        :class="{
                          invalid:
                            $v.form.requiredEmployeeCount.$invalid &&
                            $v.form.requiredEmployeeCount.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <input-row
                        :label="Fullname"
                        v-model="form.name"
                        @input="$v.form.name.$touch()"
                        @blur="$v.form.name.$touch()"
                        :class="{
                          invalid: $v.form.name.$invalid && $v.form.name.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-12 mt-4">
                    <div class="accept-terms text-left">
                      <input v-model="form.terms" type="checkbox" class="mr-2">
                      <terms/>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="career-form__buttons">
                      <a
                        href="#"
                        class="button button--main"
                        :disabled="$v.form.$invalid"
                        @click.prevent="send"
                      >
                        {{ $store.state.statics.Send }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else key="success">
            <success-message />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, minLength, email, numeric } from "vuelidate/lib/validators";
import SuccessMessage from "@/components/shared/SuccessMessage.vue";
import Terms from "@/components/shared/Terms.vue";

export default {
  data() {
    return {
      selected: "",
      form: {
        clinicName: "",
        name: "",
        email: "",
        contactNumber: "",
        address: "",
        requiredEmployeeCount: "",
        terms: false
      },
      formSended: false,
    };
  },
  computed: {
    ...mapState({
      Fullname: (state) => state.statics.Fullname,
      Email: (state) => state.statics.Email,
      Phone: (state) => state.statics.Phone,
      Address: (state) => state.statics.Address,
      ClinicName: (state) => state.statics.ClinicName,
      StaffAmount: (state) => state.statics.StaffAmount,
    }),
  },
  methods: {
    send() {
      this.$store.dispatch("getEmployee/send", this.form).then((res) => {
        if (res) {
          // this.form = {
          //   clinicName: "",
          //   name: "",
          //   surname: "",
          //   email: "",
          //   contactNumber: "",
          //   address: "",
          //   requiredEmployeeCount: "",
          // };
          this.formSended = true;
        }
      });
    },
  },
  validations: {
    form: {
      clinicName: {
        required,
        minLength: minLength(3),
      },
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        minLength: minLength(3),
        email,
      },
      contactNumber: {
        required,
        minLength: minLength(3),
      },
      address: {
        required,
        minLength: minLength(3),
      },
      requiredEmployeeCount: {
        required,
        numeric,
      },
      terms: {
        checked(val) {
          return this.form.terms ? true : val;
        }
      }
    },
  },
  components: {
    SuccessMessage,
    Terms
  },
};
</script>
