<template>
  <div class="page-cover">
    <div class="page-cover__image" v-if="cover">
      <img :src="cover.image" />
    </div>
    <div class="page-cover__content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="page-cover__title" v-if="cover.title">
              {{ cover.title }}
            </div>
            <div class="page-cover__text editor" v-if="cover.description" v-html="cover.description">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-cover",
  props: {
    cover: {
      type: Object,
    },
  },
};
</script>
