<template>
  <span v-html="termsText">
  </span>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      terms: (state) => state.terms
    }),
    termsText() {
      let lang = this.$store.state.lang;
      if (lang === "de")
        return `Ich stimme den <a href="`+this.terms+`" target="blank">Nutzungsbedingungen</a> zu `;
      if (lang === "en")
        return `I agree to the  <a href="`+this.terms+`" target="blank">terms of service</a>`;
      if (lang === "ru")
        return `Я согласен с условиями <a href="`+this.terms+`" target="blank">предоставления услуг</a> `;
      if (lang === "tr")
        return `<a href="`+this.terms+`" target="blank">Hizmet Koşullarını</a> kabul ediyorum`;
      return `<a href="`+this.terms+`" target="blank">İstifadəçi qaydalarını</a> qəbul edirəm`;
    }
  }
};
</script>
